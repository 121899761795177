import React from 'react';
import './style/ecoTrack/EmpowerChange.css'
function EmpowerChange() {
    return (
        <div className='containergraph'>
            <div className='graphContainer'>
                <div className='graphLogo'>
                    <img src='./assets/img/ecoTrack/Images/Asset 23ecoprism logo d.png' alt='Logo' />
                </div>
                <div className='graphTextContainer'>
                    <div className='empowTitle '>
                        <p>Empower Change to reduce your carbon footprint</p>
                    </div>
                    <div className='empowSubtitle'>
                        <p> Few proven ways to do so</p>
                    </div>
                    <div className='card' style={{ width: '300px' }}>
                        <table style={{marginBottom:'10px'}}>
                            <tbody>
                                <tr className='empoFirstCard'>
                                    <td  className="Empocontainer">
                                        <div className="Empocontainer">
                                            <div className="Empocircle">
                                            </div>
                                            <div className="Emposmaller">
                                            </div>
                                            <div className="Empoicon">
                                                <img className='Empoicon' src="./assets/img/ecoTrack/Icons/green/Energy efficient.png" alt="Icon" />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='card-title'>
                                            <p>
                                                Low Carbon Technology
                                            </p>
                                        </div>
                                        <div class="card-body">
                                            <p>
                                                Choose products with energy-efficient certification.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                </table>
                                <table  style={{marginBottom:'10px'}}>
                            <tbody>
                                <tr className='empoSecondCard'>
                                    <td  className="Empocontainer">
                                        <div className="Empocontainer">
                                            <div className="Empocircle">
                                            </div>
                                            <div className="Emposmaller">
                                            </div>
                                            <div className="Empoicon">
                                                <img className='Empoicon' src="./assets/img/ecoTrack/Icons/green/Green Energy.png" alt="Icon" />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='card-title'>
                                            <p>
                                            Low Carbon Energy
                                            </p>
                                        </div>
                                        <div class="card-body">
                                            <p>
                                            Be mindful of your energy source, consider green energy plans.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                </table>
                                <table  style={{marginBottom:'10px'}}>
                            <tbody>
                                <tr className='empoSecondCard'>
                                    <td  className="Empocontainer">
                                        <div className="Empocontainer">
                                            <div className="Empocircle">
                                            </div>
                                            <div className="Emposmaller">
                                            </div>
                                            <div className="Empoicon">
                                                <img className='Empoicon' src="./assets/img/ecoTrack/Icons/green/Plant based diet.png" alt="Icon" />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='card-title'>
                                            <p>
                                            Bio-Based Solution
                                            </p>
                                        </div>
                                        <div class="card-body">
                                            <p>
                                            Shift to a plant-based diet for lower emissions.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>);

}
export default EmpowerChange;