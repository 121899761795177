import React from 'react';
import './style/ecoTrack/ComparisonEco.css';
import { useNavigate } from 'react-router-dom';
function ComparisonEco() {
    const Air = localStorage.getItem('AirTravel');
    const Accommodation = localStorage.getItem('Accommodation');
    const Local = localStorage.getItem('Local');
    const name = localStorage.getItem('name');
    const Ice = localStorage.getItem('Ice');
    const Tree = localStorage.getItem('Tree');
    const Video = localStorage.getItem('Video');
    const total = parseFloat(Air)+parseFloat(Accommodation)+parseFloat(Local);
    const navigate = useNavigate();

    const handleButtonClick = () => {
        // Use the history object to navigate to the desired route
        navigate('/empowerChange')
    };
    return (
        <div className='containercompar'>
            <div className='comparContainer'>
                <div className='comparLogo'>
                    <img src='./assets/img/ecoTrack/Images/Asset 23ecoprism logo d.png' alt='Logo' />
                </div>
                <div className='comparTextContainer'>
                    <div className='comparTitle'>
                        <p>Hi {name}!</p>
                    </div>
                    <div className='comparisonSubTitle'>
                        <p>
                            Your carbon footprint {total.toFixed(2)} tCO2e which is equal to
                        </p>
                    </div>
                    <table className='comparisonImg'>
                        <tbody>
                            <tr>
                                <td>
                                    <img src='./assets/img/ecoTrack/Images/Arctic ice.jpg' alt='article'/>
                                </td>
                                <td>
                                    <tr className='comparPadding'>
                                        <td className='comparNumber'>
                                            {parseFloat(Ice).toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr className='comparPadding'>
                                        <td className='comparPragrah'>
                                            Cubic meter of Arctic Ice Loss
                                        </td>
                                    </tr>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <img src='./assets/img/ecoTrack/Images/Trees.jpg' alt='tree' />

                                </td>
                                <td>
                                    <tr className='comparPadding'>
                                        <td className='comparNumber'>
                                            {parseFloat(Tree).toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr className='comparPadding'>
                                        <td className='comparPragrah'>
                                        Years, 100 trees will take to offset
                                        </td>
                                    </tr>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src='./assets/img/ecoTrack/Images/Video Streaming.jpg' alt='video' />

                                </td>
                                <td>
                                    <tr className='comparPadding'>
                                        <td className='comparNumber'>
                                            {parseFloat(Video).toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr className='comparPadding'>
                                        <td className='comparPragrah'>
                                        Hours of video streaming in Europe
                                        </td>
                                    </tr>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        {/* <button type="button" onClick={handleButtonClick} className='graphButton'>Act Now</button> */}

                    </div>
                </div>

            </div>
        </div>
    );
}



export default ComparisonEco;