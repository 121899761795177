import React from 'react';
import './style/ecoTrack/Graph.css';
import 'chart.js/auto';
import { useRef,useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { color } from 'chart.js/helpers';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { Chart, Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
function Graph() {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        TreemapController,
        TreemapElement
    );
    
    function truncateText(text, maxLength) {
        if (text.length <= maxLength) {
          return text;
        } else {
          return text.slice(0, maxLength) + '...';
        }
    }

    const Air = localStorage.getItem('AirTravel');
    const Accommodation = localStorage.getItem('Accommodation');
    const Local = localStorage.getItem('Local');
    const name = localStorage.getItem('name');
    const total = parseFloat(Air)+parseFloat(Accommodation)+parseFloat(Local);
    const doughnutOptions = {
        plugins: {
          tooltip: {
            enabled: true,
          },
        },
    };
    const doughnutData1 = {
        labels: ["International Travel", "Accomodation", "Local travel"],
        datasets: [
          {
            data: [Air, Accommodation, Local],
            backgroundColor: [
              "rgb(242,165,152)",
              "rgb(255,232,157)",
              "rgb(236,107,109)"
            ]
          }
        ],
      
        plugins: {
          labels: {
            render: "percentage",
            fontColor: ["green", "white", "red"],
            precision: 2,
            fontSize: 16, // Customize font size if needed
            position: "border", // Display the values outside the doughnut
          }
        },
        
        text: "1%"
    };
    
    const data = [
        { name: 'International travel', capacityMW: (Air*1000), dataCoverage: Air },
        { name: 'Accomodation', capacityMW: (Accommodation*1000), dataCoverage: Accommodation },
        { name: 'Local travel', capacityMW: (Local*1000), dataCoverage: Local },
      ];
      
      const options = {
        plugins: {
          title: {
            display: true,
            text: 'Carbon Footprint',
          },
          legend: {
            display: false,
          },
          tooltip: {
            mode: 'nearest',
            intersect: false,
            displayColors: true,
            callbacks: {
              title(items) {
                return items[0].raw._data.name;
              },
              label(item) {
                const {
                  _data: { capacityMW, dataCoverage },
                } = item.raw;
                // return [
                //   `Export capacity: ${capacityMW} MW`,
                //   `Data Coverage: ${dataCoverage * 100}%`,
                // ];
                return [
                    `Emmission: ${capacityMW} kg of CO2e`,
                ];
              },
            },
          },
        },
      };
      
      const config = {
        type: 'treemap',
        data: {
          datasets: [
            {
              tree: data,
              key: 'capacityMW',
              labels: {
                display: true,
                // formatter: (context) => context.raw._data.name,
                formatter: (context) => {
                    const { _data } = context.raw;
                    const { name, capacityMW } = context.raw._data;
                    return [name, `${capacityMW} kg of CO2e`];
                }
              },
              backgroundColor(context) {
                if (context.type !== 'data') return 'transparent';
                const { dataCoverage } = context.raw._data;
                return dataCoverage === 0
                  ? color('grey').rgbString()
                  : color('green').alpha(dataCoverage).rgbString();
              },
            },
          ],
        },
      };

    const navigate = useNavigate();

    const handleButtonClick = () => {
        // Use the history object to navigate to the desired route
        navigate('/comparison')
    };
    const [loading, setLoading] = React.useState(false);
    //Air Travel Api
    const emissions = async (e) => {
        setLoading(true);
        try {
            let rest = await fetch("https://ecoprismapi.azurewebsites.net/GetEquivalentImpact", {
                method: "GET",
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'emissions': total
                }
            });
            if (rest.status === 200) {
                const responseJson = await rest.json();
                if (responseJson.success === false) {
                    setLoading(false);
                }
                else if (responseJson.status === 400) {
                    setLoading(false);
                }
                else {
                    
                    setLoading(false);
                    localStorage.setItem('Ice', JSON.stringify(responseJson.data[0].cO2eTons));
                    localStorage.setItem('Tree', JSON.stringify(responseJson.data[1].cO2eTons));
                    localStorage.setItem('Video', JSON.stringify(responseJson.data[5].cO2eTons));
                }
            }
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }
    useEffect(() => {
        emissions();
    }, []);
    
    return (
        <div className='containergraph'>
            <div className='graphContainer'>
                <div className='graphLogo'>
                    <img src='./assets/img/ecoTrack/Images/Asset 23ecoprism logo d.png' alt='Logo' />
                </div>
                <div className='graphTextContainer'>
                    <div className='graphTitle'>
                        <p>Hi {truncateText(name,10)}!</p>
                    </div>
                    <div className='graphSubTitle'>
                        <p> Here is your carbon footprint</p>
                    </div>
                    <div className='graphSubTitle2'>
                        <p>{total.toFixed(2)} tCO2e</p>
                    </div>
                    <div className=''>
                        <p>Join us at <span style={{fontWeight:'700'}}>Booth 551</span> to Dive into Sustainability & Emissions! Explore our Power BI report for detailed insights.</p>
                    </div>
                    <div >
                    <Doughnut
                        data={doughnutData1}
                        options={doughnutOptions}
                    />    
                    {/* <Chart type="treemap" data={config.data} options={options} /> */}
                    </div>
                    {/* <table>
                        <tbody>
                            <tr>
                                <td className='graphInternational'>
                                    <tr>
                                        <td>
                                        
                                            <p className='graphInternationalP'>
                                                International travel
                                            </p>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <p className='graphInternationalP2'>
                                                {Air} Metric tons of CO2e
                                            </p>
                                        </td>
                                    </tr>

                                </td>
                                <td>
                                    <tr className='graphAccomdation'>

                                        <td className='graphAccomdation'>
                                            <tr>
                                                <td>
                                                    <p className='graphInternationalP'>
                                                        Accomodation
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p className='graphInternationalP2'>{Accommodation} Metric tons of CO2e</p>
                                                </td>
                                            </tr>

                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='graphLocalTravel'>
                                            <p  className='graphInternationalP'>Local travel</p>
                                            <p className='graphInternationalP2'>{Local} Metric tons of CO2e</p>
                                        </td>
                                    </tr>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                    <button type="button" onClick={handleButtonClick} className='graphButton'>Understand the significance of your travel emissions</button>
                    
                </div>
            </div>
        </div>);
    
}
export default Graph;