// Home.js
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import '../../App.css'; // Import your CSS for styling

function Home() {
  return (
    <div className='container d-flex justify-content-center align-items-center'>
      <div className="app-container">
        <div className="center-content">
          <div>
            <img src="./assets/img/ecoTrack/Images/Logo.png" alt="Logo" className="logoHome" />

          </div>

          <div >

            <p className="title center-content" >Enabling individuals</p>

          </div>
          <div>
              <p className="subtitleHome center-content">to effortlessly track their carbon footprint from travel</p>
            
          </div>
          <div className='row'>
            <div className='col'>
              <img src="./assets/img/ecoTrack/Images/QR_code.png" alt="App Image" className="app-image" />

            </div>
          </div>

          <div className=' center-content'>
            <p className='prgFooter center-content'>Unlock personalized insights by scanning your QR code</p>

          </div>

        </div>

      </div>
    </div>
  );
}

export default Home;