import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './style/ecoTrack/Carousel.css';
import { useNavigate } from 'react-router-dom';
// import SignInForm from './SignInForm';
import Step1 from './Step1';
const EcoTrackCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startClicked, setStartClicked] = useState(false); // State variable to track if "Start" button is clicked

    const handleNext = () => {
        if (currentIndex === 1) {
            // If currentIndex is 1 (when the second slide is showing), setStartClicked to true
            setStartClicked(true);
        } else {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
        }
    };

    const carouselData = [
        {
            logo: './assets/img/ecoTrack/Images/Logo.png',
            title:'Enabling individuals',
           subtitle:'to effortlessly track their carbon footprint from travel',
            disc: "Your individual travel emissions contribute to your company's Scope 3 emissions profile",
            image: './assets/img/ecoTrack/Images/Group 4.png',
        },
        {
            subtitle: 'Discover your impact in 3 simple steps:',
            image: './assets/img/ecoTrack/Images/Group 474.png',
        },
        // Add more data for additional slides
    ];

    // Conditional rendering of the button text and content
    let buttonText = 'Next';
    let showLogo = true;
    let showTitle = true;
    let showDisc = true;
    let font_size = 17;
    let width = '60%';
    let marginTop = '0px';
    let content = null; // The component to render when "Start" button is clicked
    const navigate = useNavigate();
    if (currentIndex === 1 && !startClicked) {
        buttonText = 'Start'; // Change to your desired button text
        font_size = 26;
        width = '65%';
        showLogo = false; // Hide the logo
        showTitle = false; // Hide the logo
        showDisc = false; // Hide the logo
        marginTop = '50px';
        
    } else if (startClicked) {
        //content = <Step1 />;
        navigate('/signIn');
        
    }

    return (
        <div className="ecoTrack-carousel-container">
            {startClicked ? (
                <div className="full-screen-step1">{content}</div>
            ) : (
                <div className="carousel-container">
                    <Carousel
                        showArrows={false}
                        showStatus={false}
                        showThumbs={false}
                        selectedItem={currentIndex}
                        onChange={setCurrentIndex}
                    >
                        {carouselData.map((slide, index) => (
                            <div key={index} className="carousel-slide" >
                                {showLogo && <img src={slide.logo} alt="Logo" className='logo' />}
                                <div className="carousel-content" style={{marginTop:marginTop}}>
                                    {showTitle && <h1 className='carouselTitle'>{slide.title}</h1>}
                                    <div>
                                    <p className='subtitle' style={{ fontSize: font_size }}>
                                        {slide.subtitle}
                                    </p>
                                        </div>
                                    <img src={slide.image} style={{ width: width }} alt={`Image ${index + 1}`} />
                                    {showDisc && <p className='carouselDisc'>{slide.disc}</p>}
                                    <div className='next-button-container'>
                                        <button onClick={handleNext} className="next-button">
                                            {buttonText}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            )}
        </div>
    );
};

export default EcoTrackCarousel;
