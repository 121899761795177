import React, { useState } from 'react';
import './style/ecoTrack/SignIn.css';
import Countries from './countries.json';
import { Redirect } from 'react-router-dom';
import Select2 from 'react-select';
import { useNavigate } from 'react-router-dom';
function SignInForm() {
    // const [formData, setFormData] = useState({
    //     name: '',
    //     companyName: '',
    //     email: '',
    //     phoneNumber: '',
    //     designation: '',
    //     country: '',
    // });
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [designation, setDesignation] = useState("");
    const [country, setCountry] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const navigate = useNavigate();

    const handleCountry = (e) => {
        console.log(e.name);
        setCountry(e.name);

    }

    const handleSubmit = (e) => {
        localStorage.setItem('name', name);
        localStorage.setItem('companyName', companyName);
        localStorage.setItem('email', email);
        localStorage.setItem('designation', designation);
        localStorage.setItem('country', country);
        localStorage.setItem('phoneNumber', phoneNumber);
        navigate('/step')
    };

    return (
        <div className='container'>
            <div className='container'>
                <div className='header'>
                <p>Unlock personalized insights by entering your details</p>

                </div>
                <div className='signInForm'>
                    <form onSubmit={handleSubmit}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        Name*
                                    </td>
                                    <td className='formDesgin'>
                                        <input
                                            type="text"
                                            placeholder="Enter your name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Company Name*
                                    </td>
                                    <td className='formDesgin'>
                                        <input
                                            type="text"
                                            placeholder="Enter company name"
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Email Address*
                                    </td>
                                    <td className='formDesgin'>
                                        <input
                                            type="email"
                                            placeholder="Enter email address"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Phone Number
                                    </td>
                                    <td className='formDesgin'>
                                        <input
                                            type="tel"
                                            placeholder="Enter phone number"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Designation*

                                    </td>
                                    <td className='formDesgin'>
                                        <input
                                            type="text"
                                            placeholder="Enter your designation"
                                            value={designation}
                                            onChange={(e) => setDesignation(e.target.value)}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Country*
                                    </td>
                                    <td>
                                        <Select2
                                            className='formDesginSelect'
                                            options={Countries}
                                            placeholder="Select"
                                            onChange={handleCountry}
                                            getOptionLabel={(option) => option.name }
                                            getOptionValue={(option) => option.name}
                                            getOptionSelected={(option) => option.name === country}

                                        />
                                        {/* <select
                                            className='formDesginSelect'
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            aria-label="Default select example"
                                        >
                                            <option value="" disabled hidden>Select country</option>
                                            {Countries && Countries.map((status) => (
                                                <option value={status.name} >{status.name}</option>
                                            ))}
                                                
                                        </select> */}
                                       
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                        <div className='signInButton'>
                            <button type="submit">Next</button>

                        </div>




                    </form>
                </div>

            </div>

        </div>
    );
}

export default SignInForm;
