
import './App.css';
import Home from './components/ecotrack/Home';
import EcoTrackCarousel from './components/ecotrack/Carousel';
import SignInForm from './components/ecotrack/SignInForm';
import Graph from './components/ecotrack/Graph';
import ComparisonEco from './components/ecotrack/ComparisonEco';
import EmpowerChange from './components/ecotrack/EmpowerChange';
import Step1 from './components/ecotrack/Step1';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='/'>
          <Route index element={<Home />} />
        </Route> */}
        <Route  path='/carousel'index element={<EcoTrackCarousel />} />
        <Route  path='/signIn'index element={<SignInForm />} />
        <Route  path='/graph'index element={< Graph/>} />
        <Route  path='/comparison'index element={<ComparisonEco />} />
        <Route  path='/step'index element={<Step1 />} />
        <Route  path='/empowerChange'index element={<EmpowerChange />} />
        <Route  path='/'index element={<EcoTrackCarousel />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
