import React from 'react';
import { useRef,useEffect, useState } from "react";
import Airport from './airports.json';
import Select2 from 'react-select';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import PlacesAutocomplete,{ geocodeByAddress, getLatLng } from "react-places-autocomplete";
import {
    Stepper,
    Step,
    StepLabel,
    Container,
    Typography,
    Divider,
    Checkbox,
    FormControlLabel,
    TextField,
    Select,
    MenuItem,
    Button,
    Grid,
    Tabs,
    Tab,
    Icon,
    RadioGroup,
    FormControl,
    Radio,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Box,
    InputAdornment,
} from '@mui/material';
import './style/ecoTrack/Steps.css';
import Graph from './Graph';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CheckIcon from '@mui/icons-material/Check';
import { Padding } from '@mui/icons-material';
var cityText = '';
var distance_travelled = '';
var inputValueLocationFromT = '';
var inputValueLocationToT = '';
function Step1() {
    const steps = ['International Travel', 'Accommodation', 'Local Travel'];
    const imageTitle = ['./assets/img/ecoTrack/Icons/Yellow/International Travel.png', './assets/img/ecoTrack/Icons/Yellow/Accomodation.png', './assets/img/ecoTrack/Icons/Yellow/Local Travel.png'];
    // const internationalIcon = './assets/img/ecoTrack/Icons/Yellow/International Travel.png';
    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [selectedFuelOption, setSelectedFuelOption] = React.useState(''); // Initial selected radio option
    const [selectedOption, setSelectedOption] = React.useState('option1'); // Initial selected radio option
    const [selectedOptionLand, setSelectedOptionLand] = React.useState('Car'); // Initial selected radio option
    const [selectedChoice, setSelectedChoice] = React.useState(''); // Initial selected choice
    const [locations, setLocations] = React.useState([]);
    const [selectedOptionDest, setSelectedOptionDest] = React.useState('miles'); // Initial selected radio option
    const [numberOfPeople, setNumberOfPeople] = React.useState(1); // Initial number of people
    const [numberOfDays, setNumberOfDays] = React.useState(1); // Initial number of Days
    const [numberOfNights, setNumberOfNights] = React.useState(0); // Initial number of Nights
    const [selectedOptionVehicle, setSelectedOptionVehicle] = React.useState('optionVehicle1'); // Initial selected radio option
    const [selectedLocationTo, setSelectedLocationTo] = React.useState('choiceLocationTo1'); // Initial selected choice
    const [selectedLocationFrom, setSelectedLocationForm] = React.useState('choiceLocationFrom1'); // Initial selected choice
    const [selectedChoiceAcc, setSelectedChoiceAcc] = React.useState(''); // Initial selected choice
    const [selectedChoicePlace, setSelectedChoicePlace] = React.useState('choicePlace1'); // Initial selected choice
    const [completedSteps, setCompletedSteps] = React.useState([]);
    const [numberOfPeopleLocalTravel, setNumberOfPeopleLocalTravel] = React.useState(1); // Initial number of people LocalTravel
    const [selectedOptionVehicleTypeFuel, setSelectedOptionVehicleTypeFuel] = React.useState('optionVehicleTypeFuel'); // Initial selected radio OptionVehicleTypeFuel
    const [text, setText] = React.useState('');
    const [selectedLocalTravel, setSelectedLocalTravel] = React.useState('Car'); // Initial selected radio option
    const [selectedVehicleType, setSelectedVehicleType] = React.useState('Electric'); // Initial selected radio option
    const [selectedOptionDistanceTravelled, setSelectedDistanceTravelled] = React.useState('miles');
    const [validationError, setValidationError] = useState('');
    const [validationErrorLocationInter, setValidationErrorLocationInter] = useState('');
    const [validationErrorAccommodationType, setValidationErrorAccommodationType] = useState('');
    const [validationErrorPlaceOfStay, setValidationErrorPlaceOfStay] = useState('');
    const [validationErrorDistanceTravel, setValidationErrorDistancetravel] = useState('');
    const navigate = useNavigate();

    //Road Labels
    const [getvehicleTypeRoad, setVehicleTypeRoad] = React.useState('Electric');
    const [getroaddistance, setRoadDistance] = React.useState('');
    const [fromroad, setFromRoad] = React.useState('');
    const [toroad, setToRoad] = React.useState('');
    const [getdistanceroad, setDistanceRoad] = React.useState('miles');
    const [numberOfPeopleRoadTravel, setNumberOfPeopleRoadTravel] = React.useState(1); // Initial number of people LocalTravel
    
    
    const increasePeopleLocalTravel = () => {
        setNumberOfPeopleLocalTravel(numberOfPeopleLocalTravel + 1);
    };

    const decreasePeopleLocalTravel = () => {
        if (numberOfPeopleLocalTravel > 1) {
            setNumberOfPeopleLocalTravel(numberOfPeopleLocalTravel - 1);
        }
    };
    
    const increaseDays = () => {
        setNumberOfDays(numberOfDays + 1);
    };

    const decreaseDays = () => {
        if (numberOfDays > 1) {
            setNumberOfDays(numberOfDays - 1);
        }
    };
    
    const increasePeople = () => {
        setNumberOfPeople(numberOfPeople + 1);
    };

    const decreasePeople = () => {
        if (numberOfPeople > 1) {
            setNumberOfPeople(numberOfPeople - 1);
        }
    };

    const increasePeopleRoad = () => {
        setNumberOfPeopleRoadTravel(numberOfPeopleRoadTravel + 1);
    };

    const decreasePeopleRoad = () => {
        if (numberOfPeopleRoadTravel > 1) {
            setNumberOfPeopleRoadTravel(numberOfPeopleRoadTravel - 1);
        }
    };
    
    const increaseNights = () => {
        setNumberOfNights(numberOfNights + 1);
    };

    const decreaseNights = () => {
        if (numberOfNights > 1) {
            setNumberOfNights(numberOfNights - 1);
        }
    };
    
    const handleNextButton = () => {
        if (!completedSteps.includes(activeStep)) {
            setCompletedSteps([...completedSteps, activeStep]);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

    }
    
    const handleBackButton = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);

    }
    
    const handleChangeText = (event) => {
        setText(event.target.value);

    }
    
    const addLocation = () => {
        const newLocation = {

            from: '',
            to: '',

        };
        setLocations([...locations, newLocation]);
    };
    
    const handleLocationChange = (event, field, index) => {
        const updatedLocations = [...locations];
        updatedLocations[index][field] = event.target.value;
        setLocations(updatedLocations);
    };
    
    const CustomStepIcon = ({ index, active, completed }) => {
        return (

            <div className={`circleDot ${active ? 'active' : ''}`}>
                {completed ? <CheckIcon className='checkIcon ' /> : <span >{index + 1}</span>}
            </div>
        );
    };

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleRadioChangeVehicleTypeFuel = (event) => {
        setSelectedFuelOption(event.target.value);
    };

    const handleDistanceRoad = (event) => {
        setDistanceRoad(event.target.value);
    };
    
    const handleRadioChangeLand = (event) => {
        setSelectedOptionLand(event.target.value);
    };
    
    const handleRadioChangeVehicle = (event) => {
        setSelectedOptionVehicle(event.target.value);
    };

    const handleRadioChangeVehicleRoad = (event) => {
        setVehicleTypeRoad(event.target.value);
    };
    
    const handleRadioChangeDest = (event) => {
        setSelectedOptionDest(event.target.value);
    };
    
    const handleChoiceChange = (event) => {
        setSelectedChoice(event.target.value);
        setValidationError('');
    };
    
    const handleChoiceChangeAcc = (event) => {
        setSelectedChoiceAcc(event.target.value);
        setValidationErrorAccommodationType('');
    };
    
    const handleChoiceChangePlace = (event) => {
        setSelectedChoicePlace(event.target.value);
    };
    
    const handleChoiceLocationToChange = (event) => {
        setSelectedLocationTo(event.target.value);
    }
    
    const handleChoiceLocationFromChange = (event) => {
        setSelectedLocationForm(event.target.value);
    }
    
    const handleRadioLocalTravel = (event) => {
        const travelType=event.target.value;
        const elementToHide = document.querySelector('.vehicle-type');
        if(travelType === 'Car'){
            elementToHide.style.display = '';
        }
        else{
            elementToHide.style.display = 'none';
        }
        setSelectedLocalTravel(event.target.value);
        console.log();
    };

    const handleRadioVehicleType = (event) => {
        setSelectedVehicleType(event.target.value);
    };
    
    const handleRadioDistanceTravelled = (event) => {
        setSelectedDistanceTravelled(event.target.value);
    };
    
    


    //Autocomplete Code
    const [loading, setLoading] = React.useState(false);
    const [type, setType] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [accommodationtype, setAccommodationType] = useState('');
    // const [city, setCity] = React.useState('');
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    const [travelList, setTravelList] = useState([{ from: "", to: "" }]);
    const [airtravelList, setAirTravelList] = useState([{ from: "", to: "" }]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const options = Airport;
    const [query, setQuery] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [country1, setCountry1] = useState("");
    const [calcdistance, setCalcDistance] = useState('');
    const autoCompleteRef = useRef(null);
    const fromRef = useRef(null);
    const toRef = useRef(null);
    let autoComplete;
    let autoComplete1;
    let scriptCount = 0;

    const loadScript = (url, callback) => {
        let script = document.createElement("script");
        script.type = "text/javascript";
      
        if (script.readyState) {
          script.onreadystatechange = function() {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              callback();
            }
          };
        } else {
          script.onload = () => callback();
        }
      
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    };
      
    function handleScriptLoad(updateQuery, autoCompleteRef) {
        autoComplete = new window.google.maps.places.Autocomplete(
          autoCompleteRef.current
        );
        autoComplete.setFields(["address_components", "formatted_address"]);
        autoComplete.addListener("place_changed", () =>
          handlePlaceSelect(updateQuery)
        );
    }
      
    async function handlePlaceSelect(updateQuery) {
        console.log("Hello");
        const addressObject = autoComplete.getPlace();
        const query = addressObject.formatted_address;
        updateQuery(query);
        const results = await geocodeByAddress(query);
        // const { lat, lng } = await getLatLng(results[0]);
        let cityValue = "";
        let stateValue = "";
        let countryValue = "";
        results[0].address_components.forEach((component) => {
          if (component.types.includes("locality")) {
            cityValue = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            stateValue = component.long_name;
          }
          if (component.types.includes("country")) {
            countryValue = component.long_name;
          }
        });
        console.log(addressObject);
        //setCity(cityValue+stateValue+countryValue);
        setCity(query);
        setState(stateValue);
        setCountry(countryValue);
    }

    const handlePlaceSelect1 = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        
        setFrom(value);
    };

    const handlePlaceSelect2 = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        let countryValue = "";
        results[0].address_components.forEach((component) => {
            if (component.types.includes("country")) {
                countryValue = component.long_name;
            }
        });
        setCountry1(countryValue);
        setTo(value);
    };

    const fromSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        let countryValue = "";
        results[0].address_components.forEach((component) => {
            if (component.types.includes("country")) {
                countryValue = component.long_name;
            }
        });
        setFromRoad(value);
    };
    const toSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        let countryValue = "";
        results[0].address_components.forEach((component) => {
            if (component.types.includes("country")) {
                countryValue = component.long_name;
            }
        });
        setToRoad(value);
    };
    
    const notApplicable = () => {
        console.log("AirTravel");
        localStorage.setItem('AirTravel', 0);
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=AIzaSyD5Yfe8E1kXXtdJ36h-XDoed0l3TagQFgM&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
        );
        setSelectedTab(0);
        setCompletedSteps([...completedSteps, activeStep]);
        setActiveStep(activeStep + 1);
    }
    const notApplicable1 = () => {
        console.log("Accomodation");
        localStorage.setItem('Accommodation', 0);
        setSelectedTab(0);
        setCompletedSteps([...completedSteps, activeStep]);
        setActiveStep(activeStep + 1);
    }

    //Air Travel Api
    const airtravel = async (e) => {
        console.log(selectedTab);
        
        setLoading(true);
        if(selectedTab === 1){
            try {
                let rest = await fetch("https://ecoprismapi.azurewebsites.net/calculate-road-travel", {
                    method: "POST",
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'mode': selectedOptionLand,
                        'from': "Spain",
                        'to': "Spain",
                        'distance': getroaddistance,
                        'unit': getdistanceroad,
                        'days': 1,
                        'people': numberOfPeopleRoadTravel,
                        'type': getvehicleTypeRoad,
                        'country': "Spain"
                    },
                    body: ""
                });
                if (rest.status === 200) {
                    const responseJson = await rest.json();
                    if (responseJson.success === false) {
                        setLoading(false);
                    }
                    else if (responseJson.status === 400) {
                        setLoading(false);
                    }
                    else {
                        loadScript(
                            `https://maps.googleapis.com/maps/api/js?key=AIzaSyD5Yfe8E1kXXtdJ36h-XDoed0l3TagQFgM&libraries=places`,
                            () => handleScriptLoad(setQuery, autoCompleteRef)
                        );
                        setLoading(false);
                        localStorage.setItem('AirTravel', JSON.stringify(responseJson.data));
                        setSelectedTab(0);
                        setCompletedSteps([...completedSteps, activeStep]);
                        setActiveStep(activeStep + 1);
                    }
                }
            }
            catch (err) {
                setLoading(false);
                console.log(err);
            }
        }
        else{
            if (!selectedChoice) {
                setValidationError('Please choose a travel type');
                setLoading(true);
                return false;
            }
            if (!(travelList[0].from && travelList[0].to)) {
                setValidationErrorLocationInter('Please choose both "From" and "To" options.');
                setLoading(true);
                return false;
            }
            let airportCodeArrays;
            let requestBody;
            if(selectedOption === 'option2'){
                airportCodeArrays = airtravelList.map(item => [[item.from, item.to],[item.to, item.from]]);
                console.log(airportCodeArrays);
                requestBody = JSON.stringify(airportCodeArrays.flat());
            }
            else{
                airportCodeArrays = airtravelList.map(item => [item.from, item.to]);
                requestBody = JSON.stringify(airportCodeArrays);
            }
             
            console.log(airportCodeArrays);
            try {
                let rest = await fetch("https://ecoprismapi.azurewebsites.net/calculate-air-travel", {
                    method: "POST",
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'type': selectedChoice,
                    },
                    body: requestBody
                });
                if (rest.status === 200) {
                    const responseJson = await rest.json();
                    if (responseJson.success === false) {
                        setLoading(false);
                    }
                    else if (responseJson.status === 400) {
                        setLoading(false);
                    }
                    else {
                        loadScript(
                            `https://maps.googleapis.com/maps/api/js?key=AIzaSyD5Yfe8E1kXXtdJ36h-XDoed0l3TagQFgM&libraries=places`,
                            () => handleScriptLoad(setQuery, autoCompleteRef)
                        );
                        setLoading(false);
                        localStorage.setItem('AirTravel', JSON.stringify(responseJson.data));
                        setSelectedTab(0);
                        setCompletedSteps([...completedSteps, activeStep]);
                        setActiveStep(activeStep + 1);
                    }
                }
            }
            catch (err) {
                setLoading(false);
                console.log(err);
            }
        }
        
    }

    //Accommodation Api
    const accommodation = async (e) => {
        
        setLoading(true);
        if (!selectedChoiceAcc) {
            setValidationErrorAccommodationType('Please choose Accommodation Type');
            setLoading(false);
            return false;
            
        }

        
        
        try {
            let rest = await fetch("https://ecoprismapi.azurewebsites.net/calculate-accommodation", {
                method: "GET",
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'night': numberOfNights,
                    'type': selectedChoiceAcc,
                    'country':"Spain",
                    'city': "Barcelona"
                }
            });
            if (rest.status === 200) {
                const responseJson = await rest.json();
                if (responseJson.success === false) {
                    setLoading(false);
                }
                else if (responseJson.status === 400) {
                    setLoading(false);
                }
                else {
                    setLoading(false);
                    localStorage.setItem('Accommodation', JSON.stringify(responseJson.data));
                    setSelectedTab(0);
                    setCompletedSteps([...completedSteps, activeStep]);
                    setActiveStep(activeStep + 1);

                }
            }
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    //Local Travel Api
    const localTravel = async (e) => {
        
        setLoading(true);
        if(calcdistance === ''){
            setLoading(false);
            setValidationErrorPlaceOfStay('Please write Distance Travel');
            return false;
        }
        try {
            let rest = await fetch("https://ecoprismapi.azurewebsites.net/calculate-local-travel", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'mode': selectedLocalTravel,
                    'from': "Spain",
                    'to': "Spain",
                    'distance': calcdistance,
                    'unit': selectedOptionDistanceTravelled,
                    'days': numberOfDays,
                    'people': numberOfPeopleLocalTravel,
                    'type': selectedVehicleType,
                    'country': "Spain"
                }
            });
            if (rest.status === 200) {
                const responseJson = await rest.json();
                if (responseJson.success === false) {
                    setLoading(false);
                }
                else if (responseJson.status === 400) {
                    setLoading(false);
                }
                else {
                    const name = localStorage.getItem('name');
                    const designation = localStorage.getItem('designation');
                    const companyName = localStorage.getItem('companyName');
                    const phoneNumber = localStorage.getItem('phoneNumber');
                    const email = localStorage.getItem('email');
                    const countryName = localStorage.getItem('country');
                    const Air = localStorage.getItem('AirTravel');
                    const Accommodation = localStorage.getItem('Accommodation');
                    const Local = localStorage.getItem('Local');
                    let restt = await fetch("https://ecoprismapi.azurewebsites.net/SaveTravelEmissionsUserinfo", {
                        method: "POST",
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8'
                        },
                        body:JSON.stringify({
                            "name": name,
                            "companyName": companyName,
                            "email": email,
                            "phone": phoneNumber,
                            "designation": designation,
                            "country": countryName,
                            "airEmissions": Air,
                            "landEmissions": Local,
                            "accomodationEmissions": Accommodation,
                            "eventName": "Gartner"
                        })
                    });
                    if (restt.status === 200) {
                        const responseJsont = await restt.json();
                        if (responseJsont.success === false) {
                            setLoading(false);
                        }
                        else if (responseJsont.status === 400) {
                            setLoading(false);
                        }
                        else {

                        }
                    }
                    setLoading(false);
                    localStorage.setItem('Local', JSON.stringify(responseJson.data));
                    navigate('/graph');
                }
            }
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    // handle click event of the Add button
    const handleAddClick = () => {
        setTravelList([...travelList, { from: "", to: "" }]);
        setAirTravelList([...airtravelList, { from: "", to: "" }]);
    };


    // Neeraj Code Start
    var myHeaders = new Headers();
    myHeaders.append("mode", "Air");
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    async function fetchTravel() {
        setLoading(true);
        try {
            setIsLoading(true);
            const response = await fetch('https://ecoprismapi.azurewebsites.net/get-travel', requestOptions);
            const data = await response.json();
            if (data.success == false) {
                setLoading(false);
            }
            else if (data.status == 400) {
                setLoading(false);
            }
            else {
                setLoading(false);
                setType(data);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    async function fetchAccommodationType() {
        setLoading(true);
        try {
            setIsLoading(true);
            const response = await fetch('https://ecoprismapi.azurewebsites.net/get-accommodation-type', requestOptions);
            const data = await response.json();
            if (data.success == false) {
                setLoading(false);
            }
            else if (data.status == 400) {
                setLoading(false);
            }
            else {
                setLoading(false);
                setAccommodationType(data);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }
    

    //Fetch airports
    useEffect(() => {
        fetchTravel();
        fetchAccommodationType();
        // const delayedFunction = () => {
        // loadScript(
        //     `https://maps.googleapis.com/maps/api/js?key=AIzaSyD5Yfe8E1kXXtdJ36h-XDoed0l3TagQFgM&libraries=places`,
        //     () => handleScriptLoad(setQuery, autoCompleteRef)
        // );
        // };
        // const delay = 5000;
        // const timerId = setTimeout(delayedFunction, delay);
        // return () => clearTimeout(timerId);
        
    }, []);
    //

    return (
        <div> 
            <div>
                {loading && (
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </div>
            <div className='spaceFromTop' >
                <Container maxWidth='md' className='stepper'>
                    <Stepper activeStep={activeStep} alternativeLabel >
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel
                                    StepIconComponent={() => (
                                        <CustomStepIcon
                                            index={index}
                                            active={activeStep === index}
                                            completed={completedSteps.includes(index)}

                                        />
                                    )}
                                    className='stepperIcon'
                                >

                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Container>
            </div>
            <div>
            
                {(() => {
                    switch (activeStep) {
                        case 0:
                            return (
                                <Container className='paperBackground' >
                                    <Container className='paperBackground ' style={{ paddingBottom: '100%' }}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={1}>
                                                <Icon>
                                                    <img src={imageTitle[activeStep]} alt='step icon' className='imgDesginInternational' />
                                                </Icon>
                                            </Grid>
                                            <Grid item xs={10} >
                                                <Typography variant='h6' gutterBottom >
                                                    {steps[activeStep]}
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Divider />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <div className="notApplicationLabel">
                                                                    <FormControlLabel
                                                                        control={<Checkbox onClick={notApplicable}/>}
                                                                        label={<span className='notApplicationLabel'>Not applicable</span>}
                                                                    />
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Tabs
                                                                    value={selectedTab}
                                                                    onChange={handleChangeTab}
                                                                    variant="fullWidth"
                                                                    indicatorColor="primary"
                                                                    textColor="primary"
                                                                    className='tapIcon'
                                                                >
                                                                    <Tab key={0} icon={<img src={selectedTab === 0 ? './assets/img/ecoTrack/Icons/Yellow/Air.png' : './assets/img/ecoTrack/Icons/grey/Air.png'} alt='icon air' />} iconPosition="start" label=" Air" />
                                                                    <Tab key={1} icon={<img src={selectedTab === 1 ? './assets/img/ecoTrack/Icons/Yellow/Group 565.png' : './assets/img/ecoTrack/Icons/grey/Group 585.png'} alt='icon road' />} iconPosition="start" label=" Road" />
                                                                    {/* <Tab key={2} icon={<img src={selectedTab === 2 ? './assets/img/ecoTrack/Icons/Yellow/Group 579.png' : './assets/img/ecoTrack/Icons/grey/Group 586.png'} alt='icon rail' />} iconPosition="start" label=" Rail" />
                                                                    <Tab key={3} icon={<img src={selectedTab === 3 ? './assets/img/ecoTrack/Icons/Yellow/Layer_x0020_1.png' : './assets/img/ecoTrack/Icons/grey/water.png'} alt='icon water' />} iconPosition="start" label=" Water" /> */}
                                                                </Tabs>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {selectedTab === 0 && (
                                                    <Grid item xs={12}>
                                                        <Table>
                                                            <TableBody>
                                                                <TableRow className='internationalRadiocheck'>
                                                                    <TableCell>
                                                                        <Box >
                                                                            <FormControl component="fieldset">
                                                                                <RadioGroup
                                                                                    name="radioGroup"
                                                                                    value={selectedOption}
                                                                                    onChange={handleRadioChange}
                                                                                >
                                                                                    <Box style={{ display: "contents" }}>
                                                                                        <FormControlLabel
                                                                                            className='selectIconInternational'
                                                                                            value="option1"
                                                                                            control={<Radio />}
                                                                                            label={<Box ><span className={selectedOption === "option1" ? "selectedOptionActive" : "selectedOption"}>One Way</span></Box>}
                                                                                        />
                                                                                    </Box>
                                                                                    <Box style={{ display: "contents" }}>
                                                                                        <FormControlLabel
                                                                                            className='selectIconInternational'
                                                                                            value="option2"
                                                                                            control={<Radio />}
                                                                                            label={<Box ><span className={selectedOption === "option2" ? "selectedOptionActive" : "selectedOption"}>Round Trip</span></Box>}
                                                                                        />
                                                                                    </Box>
                                                                                    <Box style={{ display: "contents" }}>
                                                                                        <FormControlLabel
                                                                                            className='selectIconInternational'

                                                                                            value="option3"
                                                                                            control={<Radio />}
                                                                                            label={<Box ><span className={selectedOption === "option3" ? "selectedOptionActive" : "selectedOption"}>Multi City</span></Box>}
                                                                                        />
                                                                                    </Box>
                                                                                </RadioGroup>
                                                                            </FormControl>
                                                                        </Box>

                                                                    </TableCell>

                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                        {/* Code Update */}
                                                        <div className='width100'>
                                                            <div className='width40'>
                                                                <Typography className='accommodationLable'> Travel Type</Typography>
                                                            </div>
                                                            <div className='width60'>
                                                                <select className="travelSelect" aria-label="Default select example" value={selectedChoice}
                                                                    onChange={handleChoiceChange}>
                                                                    <option value="" disabled hidden>Choose a travel type</option>
                                                                    {type && type.map((item, index) => (
                                                                        <option key={index} value={item}>{item}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>       

                                                        {travelList.map((x, i) => {
                                                            const handleFromChange = (selectedOption) => {
                                                                setValidationErrorLocationInter('');
                                                                const updatedTravelList = [...travelList];
                                                                const updatedairTravelList = [...airtravelList];
                                                                updatedTravelList[i].from = selectedOption;
                                                                updatedairTravelList[i].from = selectedOption.iata_code;
                                                                setTravelList(updatedTravelList);
                                                            };

                                                            const handleToChange = (selectedOption) => {
                                                                setValidationErrorLocationInter('');
                                                                const updatedTravelList = [...travelList];
                                                                const updatedairTravelList = [...airtravelList];
                                                                updatedTravelList[i].to = selectedOption;
                                                                updatedairTravelList[i].to = selectedOption.iata_code;
                                                                setTravelList(updatedTravelList);
                                                            };

                                                            return (
                                                            <div className='width100'>
                                                                <div className='width40 mt10'>
                                                                    {i===0 && <Typography className='accommodationLable'> Location</Typography>}
                                                                    
                                                                </div>
                                                                <div className='width60 mt10'>
                                                                    <Select2
                                                                        className='formLocationInternational'
                                                                        options={options}
                                                                        placeholder="Select"
                                                                        value={x.from}
                                                                        onChange={handleFromChange}
                                                                        getOptionLabel={(option) => option.name + "," + option.city + "," + option.country}
                                                                        getOptionValue={(option) => option.iata_code}

                                                                    />
                                                                </div>
                                                                
                                                                <div className='width660 ml40'>
                                                                    <div className='arrowLocation'>
                                                                        <SyncAltIcon style={{ color: '#407775' }} />
                                                                    </div>
                                                                </div>
                                                                 
                                                                <div className='width60 ml40'>      
                                                                    <Select2
                                                                        className='formLocationInternational'
                                                                        options={options}
                                                                        placeholder="Select"
                                                                        value={x.to}
                                                                        onChange={handleToChange}
                                                                        getOptionLabel={(option) => option.name + "," + option.city + "," + option.country}
                                                                        getOptionValue={(option) => option.iata_code}
                                                                    /> 
                                                                </div>
                                                            </div>     
                                                            );
                                                        })}



                                                            
                                                        {/* Code Update End */}

                                                        <div>
                                                            <Button className='addLayerButton' style={{ display: "block" }} onClick={handleAddClick}>
                                                                + Add Layover
                                                            </Button>
                                                        </div>

                                                        <div >
                                                            {validationError && <p className="error-message">{validationError}</p>}
                                                            {validationErrorLocationInter && <p className="error-message">{validationErrorLocationInter}</p>}


                                                        </div>
                                                        <Grid item xs={12}>
                                                            {locations.map((location, index) => (
                                                                <Table>
                                                                    <TableBody >
                                                                        <TableRow>
                                                                            <TableCell><Box className={'boxSize'}></Box></TableCell>
                                                                            <TableCell>
                                                                                <TableRow key={index}  >
                                                                                    <TableCell style={{ display: 'flex' }}>
                                                                                        <Box>
                                                                                            <FormControl fullWidth>
                                                                                                <Select
                                                                                                    className='formLocationInternational'
                                                                                                    value={location.from}
                                                                                                    onChange={(event) => handleLocationChange(event, 'from', index)}
                                                                                                    variant='standard'
                                                                                                    label='Where From?'
                                                                                                >
                                                                                                    <MenuItem value='choice1'>Economy</MenuItem>
                                                                                                    <MenuItem value='choice2'>First Class</MenuItem>
                                                                                                    <MenuItem value='choice3'>Choice 3</MenuItem>
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                        </Box>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Box ml={2}> <SyncAltIcon className='addLayerIcon' style={{ marginLeft: "-14px", marginRight: "3px" }} /></Box>

                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Box>
                                                                                            <FormControl fullWidth>
                                                                                                <Select
                                                                                                    className='formLocationInternational'
                                                                                                    value={location.to}
                                                                                                    onChange={(event) => handleLocationChange(event, 'to', index)}
                                                                                                    variant='standard'
                                                                                                    label='Where To?'
                                                                                                >
                                                                                                    <MenuItem value='choice1'>Economy</MenuItem>
                                                                                                    <MenuItem value='choice2'>First Class</MenuItem>
                                                                                                    <MenuItem value='choice3'>Choice 3</MenuItem>
                                                                                                </Select>
                                                                                            </FormControl>


                                                                                        </Box>
                                                                                    </TableCell>
                                                                                </TableRow>

                                                                            </TableCell>


                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            ))}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Table>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <Divider />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <div className="internationalButtonNext">
                                                                                <Button className='buttonNext' onClick={airtravel}>
                                                                                    Next <KeyboardDoubleArrowRightIcon style={{ color: "#fff" }} />
                                                                                </Button>
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {selectedTab === 1 && (
                                                    <Grid item xs={12}>
                                                        <table >
                                                            <tbody>
                                                                <tr className="customTableRow">
                                                                    <td style={{ display: 'flex' }} >
                                                                        <Box style={{ marginRight: '20px' }}>
                                                                            <FormControl component="fieldset">
                                                                                <RadioGroup
                                                                                    name="radioGroupLand"
                                                                                    value={selectedOptionLand}
                                                                                    onChange={handleRadioChangeLand}
                                                                                >
                                                                                    <FormControlLabel
                                                                                        value="Car"
                                                                                        control={<Radio />}
                                                                                        label={
                                                                                            <Box style={{ display: "grid", alignItems: "center", marginRight: "10px" }} >
                                                                                                <Icon>
                                                                                                    <img src={selectedOptionLand === 'Car' ? './assets/img/ecoTrack/Icons/green/Car.png' : './assets/img/ecoTrack/Icons/grey/Car.png'} alt="Car" width={25} height={18} />
                                                                                                </Icon>
                                                                                                <span className={selectedOptionLand === "Car" ? "selectedOptionActive" : "selectedOption"}>Car</span>

                                                                                            </Box>}
                                                                                    />
                                                                                    
                                                                                    <FormControlLabel
                                                                                        value="Bus"
                                                                                        control={<Radio />}
                                                                                        label={<Box display="grid" alignItems="center" >
                                                                                            <Icon>
                                                                                                <img src={selectedOptionLand === "Bus" ? './assets/img/ecoTrack/Icons/green/Bus.png' : './assets/img/ecoTrack/Icons/grey/Bus.png'} alt="Bus" width={25} height={18} />
                                                                                            </Icon>
                                                                                            <span className={selectedOptionLand === "Bus" ? "selectedOptionActive" : "selectedOption"}>Bus</span>

                                                                                        </Box>}
                                                                                    />
                                                                                </RadioGroup>
                                                                            </FormControl>
                                                                        </Box>
                                                                    </td>
                                                                </tr>

                                                                <tr className='vehicle-type'>
                                                                    <td >
                                                                        <Typography className='accommodationLable'>Vehicle Type</Typography>
                                                                    </td>
                                                                    <td style={{ display: 'flex' }} >
                                                                        <Box >
                                                                            <FormControl component="fieldset" >
                                                                                <RadioGroup
                                                                                    name="radioGroupVehicle"
                                                                                    value={getvehicleTypeRoad}
                                                                                    onChange={handleRadioChangeVehicleRoad}
                                                                                    className='itemIcon'
                                                                                >
                                                                                    <FormControlLabel
                                                                                        className='itemIcon'
                                                                                        value="Electric"
                                                                                        control={<Radio />}
                                                                                        label={
                                                                                            <Box display="grid" alignItems="center">
                                                                                                <Icon>
                                                                                                    <img className='wd-30' src={selectedOptionVehicle === 'Electric' ? './assets/img/ecoTrack/Icons/green/electric_car.png' : './assets/img/ecoTrack/Icons/grey/electric_car.png'} alt="electric_car"  />
                                                                                                </Icon>
                                                                                                <span className={selectedOptionVehicle === "Electric" ? "selectedOptionActive" : "selectedOption"}>Electric</span>
                                                                                            </Box>
                                                                                        }
                                                                                    />
                                                                                
                                                                                    <FormControlLabel
                                                                                        value="Fuel"
                                                                                        control={<Radio />}
                                                                                        label={
                                                                                            <Box display="grid" alignItems="center">
                                                                                                <Icon>
                                                                                                    <img className='wd-30' src={selectedOptionVehicle === "Fuel" ? './assets/img/ecoTrack/Icons/green/fuel.png' : './assets/img/ecoTrack/Icons/grey/fuel.png'} alt="fuel"  />
                                                                                                </Icon>
                                                                                                <span className={selectedOptionVehicle === "Fuel" ? "selectedOptionActive" : "selectedOption"}>Fuel</span>
                                                                                            </Box>
                                                                                        }
                                                                                    />
                                                                                
                                                                                    <FormControlLabel
                                                                                        className='itemIcon'
                                                                                        value="Biofuel"
                                                                                        control={<Radio />}
                                                                                        label={
                                                                                            <Box display="grid" alignItems="center">
                                                                                                <Icon>
                                                                                                    <img className='wd-30' src={selectedOptionVehicle === "Biofuel" ? './assets/img/ecoTrack/Icons/green/biofuel.png' : './assets/img/ecoTrack/Icons/grey/biofuel.png'} alt="biofuel"/>
                                                                                                </Icon>
                                                                                                <span className={selectedOptionVehicle === "Biofuel" ? "selectedOptionActive" : "selectedOption"}>Biofuel</span>
                                                                                            </Box>
                                                                                        }
                                                                                    />
                                                                                </RadioGroup>
                                                                            </FormControl>
                                                                        </Box>

                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td >
                                                                        <Box>
                                                                            <Typography className='accommodationLable'>No. Of People</Typography>
                                                                        </Box>

                                                                    </td>
                                                                    <td>
                                                                        <Box display="flex" alignItems="center" style={{ height: '50px' }} className='buttonDecreasePeople' >
                                                                            <Button onClick={decreasePeopleRoad} className='buttonDecreasePeople'>-</Button>
                                                                            <Box className={"numberOfPeople"}><Typography display="flex">{numberOfPeopleRoadTravel}</Typography></Box>
                                                                            <Button onClick={increasePeopleRoad} className='buttonDecreasePeople'>+</Button>
                                                                        </Box>
                                                                    </td>
                                                                </tr>

                                                                {/* <tr>
                                                                    <td className='locationItem'>
                                                                        <Box >
                                                                            <Typography className='accommodationLable'>
                                                                                Location
                                                                            </Typography>
                                                                        </Box>
                                                                    </td>
                                                                    <td className='locationItem' >
                                                                        <td >
                                                                            <Box >
                                                                                <PlacesAutocomplete
                                                                                    value={fromroad}
                                                                                    onChange={setFromRoad}
                                                                                    className='textDistance'
                                                                                    onSelect={fromSelect}
                                                                                >
                                                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                                    <div>
                                                                                        <input className='input-width textDistance' {...getInputProps({ placeholder: '↗ Where From?' })} />
                                                                                        <div className='textDistance1'>
                                                                                            {loading ? <div>Loading...</div> : null}
                                                                                            {suggestions.map((suggestion) => (
                                                                                                <div style={{ cursor: 'pointer',padding:'5px',backgroundColor:'#F8F8F8',fontSize:'12px' }} {...getSuggestionItemProps(suggestion)}>{suggestion.description}</div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    )}
                                                                                </PlacesAutocomplete>
                                                                            </Box>
                                                                        </td>
                                                                        <td>
                                                                            <Box>
                                                                                <SyncAltIcon style={{ color: '#407775' }} />
                                                                            </Box>
                                                                        </td>
                                                                        <td>
                                                                            <Box>
                                                                                <PlacesAutocomplete
                                                                                    value={toroad}
                                                                                    onChange={setToRoad}
                                                                                    className='textDistance'
                                                                                    onSelect={toSelect}
                                                                                >
                                                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                                    <div>
                                                                                        <input className='input-width textDistance' {...getInputProps({ placeholder: '↗ Where To?' })} />
                                                                                        <div className='textDistance1'>
                                                                                            {loading ? <div>Loading...</div> : null}
                                                                                            {suggestions.map((suggestion) => (
                                                                                                <div style={{ cursor: 'pointer',padding:'5px',backgroundColor:'#F8F8F8',fontSize:'12px' }} {...getSuggestionItemProps(suggestion)}>{suggestion.description}</div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    )}
                                                                                </PlacesAutocomplete>
                                                                            </Box>
                                                                        </td>

                                                                    </td>


                                                                </tr> */}

                                                                <tr style={{ height: "50px" }}>
                                                                    <td >
                                                                        <Box >
                                                                            <Typography className='accommodationLable'>
                                                                                Distance Travelled
                                                                            </Typography>
                                                                        </Box>

                                                                    </td>
                                                                    <td>
                                                                        <td>
                                                                            <Box >
                                                                                <FormControl fullWidth>
                                                                                <input
                                                                                    type='text'
                                                                                    id="distance"
                                                                                    placeholder=" Enter Distance"
                                                                                    className='textDistance'
                                                                                    value={getroaddistance}
                                                                                    // onChange={cityInputChange}
                                                                                    onChange={(e) => setRoadDistance(e.target.value)}
                                                                                />
                                                                                </FormControl>

                                                                            </Box>
                                                                        </td>
                                                                    </td>
                                                                </tr>
                                                                <tr>

                                                                    <td>
                                                                        <td></td>
                                                                        
                                                                    </td>
                                                                    <td>
                                                                    <td>
                                                                            <tr>
                                                                                <td>
                                                                                    <td>
                                                                                        <Box marginLeft={'10px'}>
                                                                                            <FormControl className='radioGroupDestInternational'>
                                                                                                <RadioGroup
                                                                                                    className='radioGroupDestInternational'
                                                                                                    name="radioGroupDest"
                                                                                                    value={getdistanceroad}
                                                                                                    onChange={handleDistanceRoad}
                                                                                                >
                                                                                                    <FormControlLabel
                                                                                                        className='radioGroupDestInternational'
                                                                                                        value="miles"
                                                                                                        control={<Radio />}
                                                                                                        label={<Box ><span className={getdistanceroad === "miles" ? "selectedOptionActive" : "selectedOption"}>Miles</span></Box>}
                                                                                                    />

                                                                                                    <FormControlLabel
                                                                                                        className='radioGroupDestInternational'
                                                                                                        value="km"
                                                                                                        control={<Radio />}
                                                                                                        label={<Box ><span className={getdistanceroad === "km" ? "selectedOptionActive" : "selectedOption"}>Kilometers</span></Box>}
                                                                                                    />

                                                                                                </RadioGroup>
                                                                                            </FormControl>
                                                                                        </Box>
                                                                                    </td>
                                                                                </td>
                                                                            </tr>
                                                                        </td>
                                                                    </td>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <Grid item xs={12}>
                                                            <Table>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell height={'20px'}>
                                                                            <Divider />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <div className="internationalButtonNext">
                                                                                <Button className='buttonNext' onClick={airtravel}>
                                                                                    Next <KeyboardDoubleArrowRightIcon style={{ color: "#fff" }} />
                                                                                </Button>
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>

                                        </Grid>

                                    </Container>
                                </Container>
                            );
                        case 1:
                            return (
                                <Container className='paperBackground' >
                                    <Container className='paperBackground  ' style={{ paddingBottom: '100%' }}>
                                        <Grid container spacing={2} alignItems="center" >
                                            <Grid item xs={1} >
                                                <Icon>
                                                    <img src={imageTitle[activeStep]} alt='step icon' width={'29px'} height={'27px'} />
                                                </Icon>
                                            </Grid>
                                            <Grid item xs={10} >
                                                <Typography variant='h6' gutterBottom >
                                                    {steps[activeStep]}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: '-10px' }}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Divider />
                                                            </TableCell>

                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Box >
                                                                    <FormControlLabel
                                                                        control={<Checkbox onClick={notApplicable1} />}
                                                                        label={<span className='notApplicationLabel' >Not applicable</span>}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                            <Grid>
                                                {validationErrorAccommodationType && <p className="error-message">{validationErrorAccommodationType}</p>}
                                                {validationErrorPlaceOfStay && <p className="error-message">{validationErrorPlaceOfStay}</p>}

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow >
                                                            <TableCell >
                                                                <Box>
                                                                    <Typography className='accommodationLable'>
                                                                        No of Nights Stay
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell  >
                                                                <Box display="flex" place-content={'center'} minHeight={'50px'} style={{ alignItems: 'center' }} className="buttonDecreasePeople">
                                                                    <Button onClick={decreaseNights} >-</Button>
                                                                    <Box className={"numberOfPeople"}><Typography display="inline">{numberOfNights}</Typography></Box>
                                                                    <Button onClick={increaseNights} >+</Button>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell>
                                                                <Box>
                                                                    <Typography className='accommodationLable'>
                                                                        Accommodation Type
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box minHeight={'40px'}>
                                                                    <select
                                                                        value={selectedChoiceAcc}
                                                                        onChange={handleChoiceChangeAcc}
                                                                        label="Where From?"
                                                                        className='travelSelect'
                                                                        aria-label="Default select example"

                                                                    >
                                                                        <option key={0} value="" disabled hidden>Choose a travel type</option>

                                                                        {accommodationtype && accommodationtype.map((item, index) => (
                                                                            <option key={index} value={item}>{item}</option>
                                                                        ))}
                                                                    </select>

                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell>
                                                                <Box style={{ marginTop:'10px'}}>
                                                                    <Typography className='accommodationLable'>
                                                                        Place of Stay
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box  style={{ marginTop:'10px'}}>
                                                                    <div>
                                                                    {/* <input
                                                                        type='text'
                                                                        ref={autoCompleteRef} 
                                                                        id="accommodationPlaceOfStay"
                                                                        placeholder=" ↗ City name"
                                                                        className='accommodationPlaceOfCity'
                                                                        value={city}
                                                                        // onChange={cityInputChange}
                                                                        onChange={(e) => setCity(e.target.value)}
                                                                    /> */}
                                                                    <select className='travelSelectStay' value={city} onChange={(e) => setCity(e.target.value)}>
                                                                        <option value="Barcelona, Spain">Barcelona, Spain</option>
                                                                    </select>

                                                                    </div>



                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>

                                                </Table>
                                            </Grid>
                                            
                                            <Grid item xs={12} >
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Box marginTop={5} >
                                                                    <Divider />
                                                                </Box>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                            <Grid item xs={12} style={{ display: 'flex', placeContent: 'center', }}>

                                                <Button disabled={activeStep === 0} className='buttonBack' onClick={handleBackButton} style={{ marginRight: '10px' }}>
                                                    <KeyboardDoubleArrowLeftIcon />Back
                                                </Button>

                                                <Button className='buttonNext' onClick={accommodation} >
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'} <KeyboardDoubleArrowRightIcon className='keyboardColorArrow' />
                                                </Button>

                                            </Grid>

                                        </Grid>

                                    </Container>
                                </Container>
                            );
                        case 2:
                            return (
                                <Container className='paperBackground' >
                                    <Container className='paperBackground ' style={{ paddingBottom: '100%' }}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={1}>
                                                <Icon>
                                                    <img src={imageTitle[activeStep]} alt='step icon' width={'29px'} height={'27px'} />
                                                </Icon>
                                            </Grid>
                                            <Grid item xs={10} >
                                                <Typography variant='h6' gutterBottom >
                                                    {steps[activeStep]}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: '-20px' }}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Divider />
                                                            </TableCell>

                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                            <Grid>
                                                <RadioGroup
                                                    row
                                                    name="localTravel"
                                                    value={selectedLocalTravel}
                                                    onChange={handleRadioLocalTravel}
                                                    className='itemIcon'
                                                >
                                                    <Table style={{ marginLeft: "15px" }}>
                                                        <TableBody>
                                                            <TableRow className="customTableRow">

                                                                <TableCell >
                                                                    <Box style={{ width: '60px' }} >
                                                                        <FormControl>

                                                                            <FormControlLabel
                                                                                value="Car"
                                                                                control={<Radio />}
                                                                                label={
                                                                                    <Table display="flex"  >
                                                                                        <TableBody>
                                                                                            <TableRow>
                                                                                                <TableCell className='localIcon'>
                                                                                                    <Icon>
                                                                                                        <img className='wd-30' src={selectedLocalTravel === 'Car' ? './assets/img/ecoTrack/Icons/green/Car.png' : './assets/img/ecoTrack/Icons/grey/Car.png'} alt="Car" />
                                                                                                    </Icon>
                                                                                                    <span style={{ marginLeft: "3px" }} className={selectedLocalTravel === "Car" ? "selectedOptionActive" : "selectedOption"}>Car</span>

                                                                                                </TableCell>
                                                                                            </TableRow>

                                                                                        </TableBody>

                                                                                    </Table>}

                                                                            />


                                                                        </FormControl>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Box style={{ width: '60px' }} className='itemIcon'>

                                                                        <FormControl>


                                                                            <FormControlLabel

                                                                                value="Bus"
                                                                                control={<Radio />}
                                                                                label={
                                                                                    <Table display="flex"  >
                                                                                        <TableBody>
                                                                                            <TableRow>
                                                                                                <TableCell className='localIcon'>
                                                                                                    <Icon>
                                                                                                        <img className='wd-30' src={selectedLocalTravel === "Bus" ? './assets/img/ecoTrack/Icons/green/Bus.png' : './assets/img/ecoTrack/Icons/grey/Bus.png'} alt="Bus" />
                                                                                                    </Icon>
                                                                                                    <span className={selectedLocalTravel === "Bus" ? "selectedOptionActive" : "selectedOption"} style={{ fontSize: '10px' }}>Bus</span>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </TableBody>
                                                                                    </Table>} />

                                                                        </FormControl>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Box style={{ width: '70px' }} className='itemIcon'>

                                                                        <FormControl>

                                                                            <FormControlLabel
                                                                                value="Metro/Train"
                                                                                control={<Radio />}
                                                                                label={
                                                                                    <Table display="flex"  >
                                                                                        <TableBody>
                                                                                            <TableRow>
                                                                                                <TableCell className='localIcon'>
                                                                                                    <Icon>
                                                                                                        <img className='wd-30' src={selectedLocalTravel === "Metro/Train" ? './assets/img/ecoTrack/Icons/green/Train.png' : './assets/img/ecoTrack/Icons/grey/Train.png'} alt="Train"  />
                                                                                                    </Icon>
                                                                                                    <span className={selectedLocalTravel === "Metro/Train" ? "selectedOptionActive" : "selectedOption"} style={{ fontSize: '10px' }}>Metro/Train</span>

                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                }
                                                                            />

                                                                        </FormControl>
                                                                    </Box>

                                                                </TableCell>
                                                                <TableCell >
                                                                    <Box style={{ width: '60px' }} >
                                                                        <FormControl>

                                                                            <FormControlLabel
                                                                                className='itemIcon'
                                                                                value="Cycle"
                                                                                control={<Radio />}
                                                                                label={
                                                                                    <Table display="flex" >
                                                                                        <TableBody>
                                                                                            <TableRow>
                                                                                                <TableCell className='localIcon'>
                                                                                                    <Icon>
                                                                                                        <img src={selectedLocalTravel === "Cycle" ? './assets/img/ecoTrack/Icons/green/cycle.png' : './assets/img/ecoTrack/Icons/grey/Cycle.png'} alt="Train" width={25} height={18} />
                                                                                                    </Icon>
                                                                                                    <span className={selectedLocalTravel === "Cycle" ? "selectedOptionActive" : "selectedOption"} style={{ fontSize: '10px' }}>Cycle</span>

                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                }
                                                                            />

                                                                        </FormControl>
                                                                    </Box>



                                                                </TableCell>
                                                                <TableCell >
                                                                    <Box style={{ width: '60px' }} >
                                                                        <FormControl className='itemIcon'>
                                                                            <FormControlLabel
                                                                                value="Walk"
                                                                                control={<Radio />}
                                                                                label={
                                                                                    <Table display="flex"  >
                                                                                        <TableBody>
                                                                                            <TableRow>
                                                                                                <TableCell className='localIcon'>
                                                                                                    <Icon>
                                                                                                        <img src={selectedLocalTravel === "Walk" ? './assets/img/ecoTrack/Icons/green/walk.png' : './assets/img/ecoTrack/Icons/grey/walk.png'} alt="Train" width={15} height={18} />
                                                                                                    </Icon>
                                                                                                    <span className={selectedLocalTravel === "Walk" ? "selectedOptionActive" : "selectedOption"} style={{ fontSize: '10px' }}>Walk</span>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                }
                                                                            />

                                                                        </FormControl>
                                                                    </Box>




                                                                </TableCell>

                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </RadioGroup>
                                            </Grid>
                                            <Grid>
                                                {validationErrorAccommodationType && <p className="error-message">{validationErrorAccommodationType}</p>}
                                                {validationErrorPlaceOfStay && <p className="error-message">{validationErrorPlaceOfStay}</p>}

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow >
                                                            <TableCell >
                                                                <Box>
                                                                    <Typography className='accommodationLable'>
                                                                        No. Of Days
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell  >

                                                                <Box display="flex" place-content={'center'} minHeight={'50px'} style={{ alignItems: 'center' }} className="buttonDecreasePeople">
                                                                    <Button onClick={decreaseDays}>-</Button>
                                                                    <Box className={"numberOfPeople"}><Typography display="inline">{numberOfDays}</Typography></Box>
                                                                    <Button onClick={increaseDays} >+</Button>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell >
                                                                <Box>
                                                                    <Typography className='accommodationLable'>
                                                                        No. Of People
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell  >

                                                                <Box display="flex" place-content={'center'} minHeight={'50px'} style={{ alignItems: 'center' }} className="buttonDecreasePeople">
                                                                    <Button onClick={decreasePeopleLocalTravel}>-</Button>
                                                                    <Box className={"numberOfPeople"}><Typography display="inline">{numberOfPeopleLocalTravel}</Typography></Box>
                                                                    <Button onClick={increasePeopleLocalTravel} >+</Button>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className='vehicle-type'>
                                                            <TableCell >
                                                                <Box>
                                                                    <Typography className='accommodationLable'>
                                                                        Vehicle Type
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell >
                                                                <RadioGroup
                                                                    name="VehicleType"
                                                                    value={selectedVehicleType}
                                                                    onChange={handleRadioVehicleType}
                                                                    className='itemIcon'
                                                                >
                                                                    <Box >
                                                                        <TableCell>
                                                                            <FormControl >

                                                                                <FormControlLabel
                                                                                    value="Electric"
                                                                                    control={<Radio />}
                                                                                    className='itemIcon'
                                                                                    label={
                                                                                        <Table display="flex" >
                                                                                            <TableBody>
                                                                                                <TableRow>
                                                                                                    <TableCell className='localIcon'>
                                                                                                        <Icon>
                                                                                                            <img src={selectedVehicleType === 'Electric' ? './assets/img/ecoTrack/Icons/green/Car.png' : './assets/img/ecoTrack/Icons/grey/Car.png'} alt="Car" width={25} />
                                                                                                        </Icon>
                                                                                                        <span className={selectedVehicleType === "Electric" ? "selectedOptionActive" : "selectedOption"} style={{ fontSize: '10px' }}>Electric</span>

                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            </TableBody>
                                                                                        </Table>}
                                                                                />

                                                                            </FormControl>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <FormControl >

                                                                                <FormControlLabel
                                                                                    value="Fuel"
                                                                                    control={<Radio />}
                                                                                    className='itemIcon'
                                                                                    label={
                                                                                        <Table display="flex" >
                                                                                            <TableBody>
                                                                                                <TableRow>
                                                                                                    <TableCell className='localIcon'>
                                                                                                        <Icon>
                                                                                                            <img className='wd-30' src={selectedVehicleType === 'Fuel' ? './assets/img/ecoTrack/Icons/green/Fuel.png' : './assets/img/ecoTrack/Icons/grey/Fuel.png'} alt="Fuel"  />
                                                                                                        </Icon>
                                                                                                        <span className={selectedVehicleType === "Fuel" ? "selectedOptionActive" : "selectedOption"} style={{ fontSize: '10px' }}>Fuel</span>

                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            </TableBody>
                                                                                        </Table>}
                                                                                />
                                                                            </FormControl>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <FormControl >

                                                                                <FormControlLabel
                                                                                    value="Biofuel"
                                                                                    control={<Radio />}
                                                                                    className='itemIcon'
                                                                                    label={
                                                                                        <Table display="flex"  >
                                                                                            <TableBody>
                                                                                                <TableRow>
                                                                                                    <TableCell className='localIcon'>
                                                                                                        <Icon>
                                                                                                            <img className='wd-30' src={selectedVehicleType === 'Biofuel' ? './assets/img/ecoTrack/Icons/green/BioFuel.png' : './assets/img/ecoTrack/Icons/grey/BioFuel.png'} alt="BioFuel" />
                                                                                                        </Icon>
                                                                                                        <span className={selectedVehicleType === "Biofuel" ? "selectedOptionActive" : "selectedOption"} style={{ fontSize: '10px' }}>Biofuel</span>

                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    }
                                                                                />
                                                                            </FormControl>
                                                                        </TableCell>
                                                                    </Box>

                                                                </RadioGroup>

                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell width={105}>
                                                                <Box>
                                                                    <Typography className='accommodationLable'>
                                                                        Distance Travelled
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell colSpan={3}>
                                                                <TableCell colSpan={3} >
                                                                    <input
                                                                        type='text'
                                                                        id="distance"
                                                                        placeholder=" Enter Distance"
                                                                        className='textDistance'
                                                                        value={calcdistance}
                                                                        // onChange={cityInputChange}
                                                                        onChange={(e) => setCalcDistance(e.target.value)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Box ml={1} style={{
                                                                        display: 'flex', marginRight: '-15px',
                                                                        paddingLeft: '5px'
                                                                    }}>
                                                                        <FormControl>
                                                                            <RadioGroup
                                                                                name="radioGroupLand"
                                                                                value={selectedOptionDistanceTravelled}
                                                                                onChange={handleRadioDistanceTravelled}
                                                                                className='itemIcon'
                                                                            >
                                                                                <FormControlLabel
                                                                                    value="miles"
                                                                                    control={<Radio />}
                                                                                    className='itemIcon'
                                                                                    label={
                                                                                        <Box marginLeft={'3px'}>

                                                                                            <span className={selectedOptionDistanceTravelled === "miles" ? "selectedOptionActive" : "selectedOption"} style={{ fontSize: '10px' }}>Miles</span>

                                                                                        </Box>}
                                                                                />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                        <FormControl >
                                                                            <RadioGroup
                                                                                name="radioGroupLand"
                                                                                value={selectedOptionDistanceTravelled}
                                                                                onChange={handleRadioDistanceTravelled}
                                                                                className='itemIcon'
                                                                            >
                                                                                <FormControlLabel
                                                                                    value="km"
                                                                                    control={<Radio />}
                                                                                    className='itemIcon'
                                                                                    label={
                                                                                        <Box marginLeft={'3px'}>

                                                                                            <span className={selectedOptionDistanceTravelled === "km" ? "selectedOptionActive" : "selectedOption"} style={{ fontSize: '10px' }}>Kilometers</span>

                                                                                        </Box>}
                                                                                />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Box>
                                                                    <Typography className='accommodationLable'>
                                                                        Location
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box >
                                                                    <TableCell >
                                                                        <Box marginTop={'5px'} >
                                                                            <PlacesAutocomplete
                                                                                value={from}
                                                                                onChange={setFrom}
                                                                                className='textDistance'
                                                                                onSelect={handlePlaceSelect1}
                                                                            >
                                                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                                <div>
                                                                                    <input className='input-width textDistance' {...getInputProps({ placeholder: '↗ Where From?' })} />
                                                                                    <div className='textDistance1'>
                                                                                        {loading ? <div>Loading...</div> : null}
                                                                                        {suggestions.map((suggestion) => (
                                                                                            <div style={{ cursor: 'pointer',padding:'5px',backgroundColor:'#F8F8F8',fontSize:'12px' }} {...getSuggestionItemProps(suggestion)}>{suggestion.description}</div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                                )}
                                                                            </PlacesAutocomplete>
                                                                        
                                                                        
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box margin={'2px'} >
                                                                            <SyncAltIcon style={{ paddingTop: '5px', color: '#407775' }} />
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box>
                                                                        <PlacesAutocomplete
                                                                                value={to}
                                                                                onChange={setTo}
                                                                                className='textDistance'
                                                                                onSelect={handlePlaceSelect2}
                                                                            >
                                                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                                <div>
                                                                                    <input className='input-width textDistance' {...getInputProps({ placeholder: '↗ Where To ?' })} />
                                                                                    <div className='textDistance1'>
                                                                                        {loading ? <div>Loading...</div> : null}
                                                                                        {suggestions.map((suggestion) => (
                                                                                            <div {...getSuggestionItemProps(suggestion)} style={{ cursor: 'pointer',padding:'5px',backgroundColor:'#F8F8F8',fontSize:'12px' }}>{suggestion.description}</div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                                )}
                                                                            </PlacesAutocomplete>
                                                                        </Box>
                                                                    </TableCell>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Box style={{ height: "10px", marginTop: "30px" }}>
                                                                    <Divider />
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                            <div>
                                                            {/* {validationError && <p className="error-message">{validationError}</p>} */}
                                                            {validationErrorDistanceTravel && <p className="error-message">{validationErrorDistanceTravel}</p>}
                                            </div>
                                            
                                            <Grid item xs={12} style={{ display: 'flex', placeContent: 'center', }}>
                                                <Button disabled={activeStep === 0} className='buttonBack' onClick={handleBackButton} style={{ marginRight: '10px' }}>
                                                    {cityText =''}
                                                    <KeyboardDoubleArrowLeftIcon />Back
                                                </Button>

                                                <Button className='buttonNext' onClick={localTravel} >
                                                    Next <KeyboardDoubleArrowRightIcon />
                                                </Button>

                                            </Grid>
                                            

                                        </Grid>
                                    

                                    </Container>
                                </Container >
                            );
                        default:
                            return null;
                    }
                })()}
            </div>
        </div>
    );

    // return (
    //     <div className='spaceFromTop' >
    //         <Container maxWidth='md' className='stepper'>
    //             <Stepper activeStep={activeStep} alternativeLabel >
    //                 {steps.map((label, index) => (
    //                     <Step key={label}>
    //                         <StepLabel
    //                             StepIconComponent={() => (
    //                                 <CustomStepIcon
    //                                     index={index}
    //                                     active={activeStep === index}
    //                                     completed={completedSteps.includes(index)}

    //                                 />
    //                             )}
    //                             className='stepperIcon'
    //                         >

    //                             {label}
    //                         </StepLabel>
    //                     </Step>
    //                 ))}
    //             </Stepper>
    //         </Container>
    //         <div>
    //             {activeStep === steps.length ? (
    //                 <div>
    //                     <Graph />
    //                 </div>

    //             ) : (
    //                 <div>
    //                     <StepContent step={activeStep} />
    //                 </div>
    //             )}


    //         </div>
    //     </div>



    // );
}

export default Step1;
